@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  :root {
    --color-brand: 17 24 39;
    /* --account-select-modal-margin-4: 1rem; */
  }
  html,
  body {
    /* --onboard-modal-right: 10rem; */
    scroll-behavior: smooth;

    @apply bg-body font-body antialiased dark:bg-dark dark:text-white;
  }

  .dynamic-html h6 {
    @apply font-medium;
  }
  .dynamic-html p {
    @apply mb-3;
  }
}

@layer utilities {
  .text-case-inherit {
    text-transform: inherit;
  }
  .letter-space-inherit {
    letter-spacing: inherit;
  }
  .word-break-all {
    word-break: break-all;
  }
  .animation-delay-200 {
    animation-delay: 200ms;
  }
  .animation-delay-500 {
    animation-delay: 500ms;
  }
  .animation-delay-700 {
    animation-delay: 700ms;
  }
  .text-vertical {
    writing-mode: tb;
    text-orientation: upright;
  }
}

/* Hide spin button for input type number */
input.spin-button-hidden::-webkit-outer-spin-button,
input.spin-button-hidden::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*================================================================
Styles for Swiper slider
================================================================*/
.swiper {
  @apply flex flex-col;
}

.swiper-wrapper {
  @apply order-first;
}

.swiper-scrollbar {
  @apply relative rounded bg-gray-200 dark:bg-gray-600;
}

.swiper-horizontal > .swiper-scrollbar {
  @apply z-10 mt-4 mb-0 h-0.5 w-full sm:mt-6;
}

.swiper-vertical > .swiper-scrollbar {
  @apply absolute top-0 right-1 z-10 h-full w-0.5;
}

.swiper-scrollbar-drag {
  @apply relative rounded bg-gray-900 dark:bg-light-dark;
}

.swiper-horizontal .swiper-scrollbar-drag {
  @apply top-[-1px] left-0 h-[3px] w-full shadow-sm;
}

.swiper-vertical .swiper-scrollbar-drag {
  @apply left-[-1px] top-0 h-full w-[3px] shadow-sm;
}

.swiper-scrollbar-cursor-drag {
  @apply cursor-move;
}

.swiper-scrollbar-lock {
  @apply hidden;
}

.transaction-table {
  border-spacing: 0 12px;
  margin-top: -12px;
}
.transaction-table > thead tr > th {
  text-align: left;
}
.text-heading-style {
  @apply text-sm font-medium tracking-wider;
}

/* React Share button custom styles */
.product-share .react-share__ShareButton {
  @apply flex flex-col items-center justify-center;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  margin: 28px;
  border: 4px solid rgb(229, 236, 240);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgb(247, 249, 250) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
@media (min-width: 1280px) and (max-width: 1439px) {
  body {
    --onboard-modal-right: 8rem;
  }
}

@media (min-width: 1440px) and (max-width: 1560px) {
  body {
    --onboard-modal-right: 14rem;
  }
}

@media (min-width: 1561px) and (max-width: 1779) {
  body {
    --onboard-modal-right: 15rem;
  }
}

@media (min-width: 640px) and (max-width: 767px) {
  .lds-ring div {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 25px;
    margin-bottom: 50px;
  }
}

@media (max-width: 639px) {
  .lds-ring div {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 25px;
    margin-bottom: 50px;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.disable-event {
  pointer-events: none !important;
}
.bn-onboard-custom .bn-onboard-modal {
  overflow: scroll;
  z-index: 70;
}
.bn-onboard-custom.bn-onboard-modal {
  z-index: 70;
}
