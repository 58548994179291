/* Custom scrollbar design starts from here */
.os-theme-thin > .os-scrollbar-vertical {
  bottom: 14px;
  width: 14px;
  padding: 6px 0;
}

.os-theme-thin > .os-scrollbar-horizontal {
  right: 14px;
  height: 14px;
  padding: 0px 6px;
}

.os-theme-thin
  > .os-scrollbar-horizontal
  > .os-scrollbar-track
  > .os-scrollbar-handle {
  height: 100%;
  min-width: 30px;
}

.os-theme-thin.os-host-transition
  > .os-scrollbar-horizontal
  > .os-scrollbar-track
  > .os-scrollbar-handle:before {
  transition: height 0.3s, margin-top 0.3s, background 0.2s;
}

.os-theme-thin
  > .os-scrollbar-horizontal
  > .os-scrollbar-track
  > .os-scrollbar-handle:hover:before,
.os-theme-thin
  > .os-scrollbar-horizontal
  > .os-scrollbar-track
  > .os-scrollbar-handle.active:before {
  height: 4px;
  margin-top: -2px;
}

.os-theme-thin > .os-scrollbar-horizontal > .os-scrollbar-track:before,
.os-theme-thin
  > .os-scrollbar-horizontal
  > .os-scrollbar-track
  > .os-scrollbar-handle:before {
  left: 0;
  right: 0;
  height: 2px;
  top: 50%;
  margin-top: -1px;
}

.os-theme-thin > .os-scrollbar > .os-scrollbar-track {
  background: transparent;
}

.os-theme-thin > .os-scrollbar-horizontal > .os-scrollbar-track:before,
.os-theme-thin > .os-scrollbar-vertical > .os-scrollbar-track:before {
  content: '';
  display: block;
  position: absolute;
  @apply bg-black/10 dark:bg-gray-500;
}

.os-theme-thin > .os-scrollbar-vertical > .os-scrollbar-track:before,
.os-theme-thin
  > .os-scrollbar-vertical
  > .os-scrollbar-track
  > .os-scrollbar-handle:before {
  top: 0;
  bottom: 0;
  width: 2px;
  left: 50%;
  margin-left: -1px;
}

.os-theme-thin
  > .os-scrollbar-vertical
  > .os-scrollbar-track
  > .os-scrollbar-handle {
  width: 100%;
  min-height: 15px;
}

.os-theme-thin > .os-scrollbar-vertical > .os-scrollbar-track:before,
.os-theme-thin
  > .os-scrollbar-vertical
  > .os-scrollbar-track
  > .os-scrollbar-handle:before {
  top: 0;
  bottom: 0;
  width: 2px;
  left: 50%;
  margin-left: -1px;
  border-radius: 15px;
  @apply bg-black/10 dark:bg-gray-600;
}
.os-theme-thin
  > .os-scrollbar
  > .os-scrollbar-track
  > .os-scrollbar-handle:before {
  content: '';
  display: block;
  position: absolute;
  border-radius: 10px;
  @apply bg-black/30 dark:bg-gray-800;
}

.os-theme-thin
  > .os-scrollbar-vertical
  > .os-scrollbar-track
  > .os-scrollbar-handle:hover:before,
.os-theme-thin
  > .os-scrollbar-vertical
  > .os-scrollbar-track
  > .os-scrollbar-handle.active:before {
  width: 2px;
  margin-left: -1px;
  background: #666;
}

.os-theme-thin.os-host-transition
  > .os-scrollbar-vertical
  > .os-scrollbar-track
  > .os-scrollbar-handle:before {
  -webkit-transition: width 0.3s, margin-left 0.3s, background 0.2s;
  transition: width 0.3s, margin-left 0.3s, background 0.2s;
}

/* ScrollBar Height Calc */
.cart-scrollbar {
  -webkit-overflow-scrolling: touch;
  max-height: calc(100vh - 160px);
}
/* .table-scrollbar {
  background-color: green;
} */
.table-scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
}

.table-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.075);
  border-radius: 10px;
  border: 2px solid red;
}
